import React, { FC } from 'react';
import { getCellLinks } from '@grafana/ui';
import { TableCellProps } from './types';

export const ImageCell: FC<TableCellProps> = (props) => {
  const { field, cell, tableStyles, row, cellProps } = props;

  const displayValue = field.display!(cell.value);

  const links = getCellLinks(field, row);

  return (
    // @ts-ignore
    <div {...cellProps} className={tableStyles.cellContainer}>
      {!links && <img src={displayValue.text} className={tableStyles.imageCell} />}
      {links &&
        links.map((link, index) => (
          <a key={index} {...link} className={tableStyles.imageCellLink}>
            <img src={displayValue.text} className={tableStyles.imageCell} />
          </a>
        ))}
    </div>
  );
};
