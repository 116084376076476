import React, { useState, useCallback, useMemo } from 'react';
import { StandardEditorProps } from '@grafana/data';
import { Select } from '@grafana/ui';
import { Fields, Settings } from 'types';
import { EditorForm } from 'components/EditorForm';

export const FieldsEditor: React.FC<StandardEditorProps<Fields[] | undefined>> = (props) => {
  const { value, onChange, context } = props;

  const [selectedField, setSelectedField] = useState<Fields>();

  const availableFields: Fields[] = useMemo(() => {
    const _: Fields[] = [];
    if (context.data) {
      const frames = context.data;

      for (let i = 0; i < frames.length; i++) {
        for (let j = 0; j < frames[i].fields.length; j++) {
          if (value?.find((e) => e.name === frames[i].fields[j].name) === undefined) {
            _.push({
              label: frames[i].fields[j].name,
              value: frames[i].fields[j].name,
            });
          }
        }
      }
    }
    return _;
  }, [context, value]);

  const handleOnChange = useCallback(
    (newValue: Fields) => {
      let untouched: Fields[] = availableFields.filter((e) => value?.find((v) => v.value === e.value) === undefined);
      let res = [...(value || []), ...untouched].map((e) => (e.value === newValue.value ? newValue : e));
      onChange(res);
    },
    [value, availableFields, onChange]
  );

  return (
    <>
      <Select
        options={availableFields}
        value={selectedField}
        onChange={(selectableValue) => setSelectedField(availableFields.find((e) => e.value === selectableValue.value))}
      />
      {availableFields.map((field, idx) => {
        let _ = value?.find((e) => e.value === field?.value) || field;
        return (
          <EditorForm
            key={idx}
            value={_}
            onChange={handleOnChange}
            groups={(context.options as Settings)?.headerGroups}
            hidden={field.value !== selectedField?.value}
          />
        );
      })}
    </>
  );
};
