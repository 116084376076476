import { Column, Row } from 'react-table';
// @ts-ignore
import memoizeOne from 'memoize-one';

import {
  DataFrame,
  Field,
  FieldType,
  formattedValueToString,
  KeyValue,
  SelectableValue,
  VariableModel,
} from '@grafana/data';

import { DefaultCell } from './DefaultCell';
import { BarGaugeCell } from './BarGaugeCell';
import { TableCellDisplayMode, TableFieldOptions } from './types';
import { JSONViewCell } from './JSONViewCell';
import { ImageCell } from './ImageCell';
import { Dictionary, Settings } from 'types';

const selectSortType = (type: FieldType): string => {
  switch (type) {
    case FieldType.number:
      return 'number';
    case FieldType.time:
      return 'basic';
    default:
      return 'alphanumeric-insensitive';
  }
};

export function interpolate(template?: string, params?: KeyValue, variables?: VariableModel[]) {
  if (template && params) {
    try {
      // @ts-ignore
      let vars = variables
        // @ts-ignore
        ?.filter((e) => template.indexOf(e.id) === -1)
        // @ts-ignore
        .map((e) => 'var-' + e.id + '=' + encodeURIComponent(e.current.text))
        .join('&');
      let _ = { ...params, __vars: vars };
      const names = Object.keys(_);
      const vals = Object.values(_);

      if (names.filter((e) => template.indexOf(e) !== -1).every((e) => params?.[e] !== null)) {
        return new Function(...names, `return \`${template}\`;`)(...vals);
      } else {
        return undefined;
      }
    } catch (error) {
      console.error(error);
    }
  }
}

function getTextAlign(field?: Field): any {
  if (!field) {
    return 'flex-start';
  }

  if (field.config.custom) {
    const custom = field.config.custom as TableFieldOptions;

    switch (custom.align) {
      case 'right':
        return 'flex-end';
      case 'left':
        return 'flex-start';
      case 'center':
        return 'center';
    }
  }

  if (field.type === FieldType.number) {
    return 'flex-end';
  }

  return 'flex-start';
}

export function getColumns(
  data: DataFrame,
  availableWidth: number,
  columnMinWidth: number,
  settings?: Settings,
  variables?: VariableModel[]
): Column[] {
  const groups: Dictionary<any[]> = {};
  const extras: any[] = [];
  settings?.headerGroups?.forEach((e) => {
    groups[e] = [];
  });

  let hiddenFields = settings?.fieldSettings?.filter((e) => e.isHidden).map((e) => e.value);

  let filteredFields = data.fields.filter((e) => !hiddenFields?.includes(e.name));
  let fieldCountWithoutWidth = filteredFields.length;

  for (const [_, field] of filteredFields.entries()) {
    const fieldTableOptions = (field.config.custom || {}) as TableFieldOptions;
    const setting = settings?.fieldSettings?.find((e) => e.value === field.name);

    const Cell = getCellComponent(fieldTableOptions.displayMode, field);

    let column = {
      Cell: Cell,
      id: setting?.value,
      Header: setting?.label || field.name,
      accessor: (row: any, i: number) => {
        const raw = field.values.get(i);
        if ((setting?.link || setting?.linkIcon) && raw) {
          let _ = data.fields
            .map((e) => ({ key: e.name, value: e.values.get(i) }))
            .reduce((obj, e) => ({ ...obj, [e.key]: e.value }), {});
          let linkIcon = {
            ...setting.linkIcon,
            link: interpolate(setting?.linkIcon?.link, _, variables),
            tooltip: interpolate(setting?.linkIcon?.tooltip, _),
          };

          let res = {
            href: interpolate(setting?.link, _, variables),
            linkIcon: linkIcon,
            title: setting.linkText ? interpolate(setting?.linkText, _) : raw,
            tooltip: interpolate(setting?.linkTooltip, _),
          };
          return res;
        } else {
          return raw;
        }
      },
      width: fieldTableOptions.width || fieldTableOptions.minWidth || setting?.width,
      minWidth: fieldTableOptions.minWidth || columnMinWidth,
      filter: memoizeOne(filterByValue(field)),
      justifyContent: getTextAlign(field),
      sortType: selectSortType(field.type),
    };

    if (column.width) {
      if (column.width < column.minWidth && !setting?.isHidden) {
        availableWidth -= column.minWidth;
      } else {
        availableWidth -= column.width;
      }
      fieldCountWithoutWidth -= 1;
    }

    if (setting?.headerGroup && groups[setting.headerGroup]) {
      groups[setting.headerGroup].push(column);
      continue;
    } else {
      extras.push(column);
    }
  }

  const setWidths = (columns: any[]) => {
    for (const column of columns) {
      if (!column.width && column.minWidth > sharedWidth) {
        column.width = column.minWidth;
        availableWidth -= column.width;
        fieldCountWithoutWidth -= 1;
        sharedWidth = availableWidth / fieldCountWithoutWidth;
      }
    }
  };

  const setRemainingWidths = (columns: any[]) => {
    for (const column of columns) {
      if (!column.width) {
        column.width = sharedWidth;
      }
    }
  };

  // set columns that are at minimum width
  let sharedWidth = availableWidth / fieldCountWithoutWidth;
  for (let i = fieldCountWithoutWidth; i > 0; i--) {
    settings?.headerGroups?.forEach((headerGroup) => {
      setWidths(groups[headerGroup]);
    });
  }

  if (settings?.headerGroups) {
    settings?.headerGroups?.forEach((headerGroup) => {
      setRemainingWidths(groups[headerGroup]);
    });

    // divide up the rest of the space
    return (
      settings?.headerGroups?.map((headerGroup) => ({ Header: headerGroup, columns: groups[headerGroup] })) || []
    ).concat(extras);
  } else {
    setRemainingWidths(extras);
    return extras;
  }
}

function getCellComponent(displayMode: TableCellDisplayMode, field: Field) {
  switch (displayMode) {
    case TableCellDisplayMode.ColorText:
    case TableCellDisplayMode.ColorBackground:
      return DefaultCell;
    case TableCellDisplayMode.Image:
      return ImageCell;
    case TableCellDisplayMode.LcdGauge:
    case TableCellDisplayMode.BasicGauge:
    case TableCellDisplayMode.GradientGauge:
      return BarGaugeCell;
    case TableCellDisplayMode.JSONView:
      return JSONViewCell;
  }

  // Default or Auto
  if (field.type === FieldType.other) {
    return JSONViewCell;
  }
  return DefaultCell;
}

function filterByValue(field?: Field) {
  return function (rows: Row[], id: string, filterValues?: SelectableValue[]) {
    if (rows.length === 0) {
      return rows;
    }

    if (!filterValues) {
      return rows;
    }

    if (!field) {
      return rows;
    }

    return rows.filter((row) => {
      if (!row.values.hasOwnProperty(id)) {
        return false;
      }
      const value = rowToFieldValue(row, field);
      return filterValues.find((filter) => filter.value === value) !== undefined;
    });
  };
}

export function calculateUniqueFieldValues(rows: any[], field?: Field) {
  if (!field || rows.length === 0) {
    return {};
  }

  const set: Record<string, any> = {};

  for (let index = 0; index < rows.length; index++) {
    const value = rowToFieldValue(rows[index], field);
    set[value || '(Blanks)'] = value;
  }

  return set;
}

function rowToFieldValue(row: any, field?: Field): string {
  if (!field || !row) {
    return '';
  }

  const fieldValue = field.values.get(row.index);
  const displayValue = field.display ? field.display(fieldValue) : fieldValue;
  const value = field.display ? formattedValueToString(displayValue) : displayValue;

  return value;
}

export function valuesToOptions(unique: Record<string, any>): SelectableValue[] {
  return Object.keys(unique)
    .reduce((all, key) => all.concat({ value: unique[key], label: key }), [] as SelectableValue[])
    .sort(sortOptions);
}

function sortOptions(a: SelectableValue, b: SelectableValue): number {
  if (a.label === undefined && b.label === undefined) {
    return 0;
  }

  if (a.label === undefined && b.label !== undefined) {
    return -1;
  }

  if (a.label !== undefined && b.label === undefined) {
    return 1;
  }

  if (a.label! < b.label!) {
    return -1;
  }

  if (a.label! > b.label!) {
    return 1;
  }

  return 0;
}

export function getFilteredOptions(options: SelectableValue[], filterValues?: SelectableValue[]): SelectableValue[] {
  if (!filterValues) {
    return [];
  }

  return options.filter((option) => filterValues.some((filtered) => filtered.value === option.value));
}

export function sortCaseInsensitive(a: Row<any>, b: Row<any>, id: string) {
  return String(a.values[id]).localeCompare(String(b.values[id]), undefined, { sensitivity: 'base' });
}

// sortNumber needs to have great performance as it is called a lot
export function sortNumber(rowA: Row<any>, rowB: Row<any>, id: string) {
  const a = toNumber(rowA.values[id]);
  const b = toNumber(rowB.values[id]);
  return a === b ? 0 : a > b ? 1 : -1;
}

function toNumber(value: any): number {
  if (typeof value === 'number') {
    return value;
  }

  if (value === null || value === undefined || value === '' || isNaN(value)) {
    return Number.NEGATIVE_INFINITY;
  }

  return Number(value);
}
