import React from 'react';
import { Field, IconButton, useStyles2 } from '@grafana/ui';
import { Props } from './types';
import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { FilterComponent } from './FilterComponent';

export const FilterForm: React.FC<Props> = (props) => {
  const { onFiltersChange, filters, datasource, height, width, showDownloadButton, onDownloadClick, status } = props;
  const styles = useStyles2(getFilterStyles)(height, width);

  return (
    <div className={styles.filterRow}>
      {filters
        .filter((e) => e.isFilterable)
        .map((filter, index) => (
          <FilterComponent
            key={index}
            value={filter}
            datasource={datasource}
            onChange={onFiltersChange}
            className={styles.filterItem}
          />
        ))}
      {showDownloadButton ? (
        <Field label="Action" className={styles.buttonWrapper}>
          <IconButton
            className={styles.button}
            onClick={onDownloadClick}
            name={status === 'loading' ? 'fa fa-spinner' : 'download-alt'}
            size="lg"
            tooltip="Download voyage report"
          />
        </Field>
      ) : null}
    </div>
  );
};

const getFilterStyles = (theme: GrafanaTheme2) => (height: number, width: number) => {
  const headerBg = theme.colors.background.secondary;
  return {
    filterRow: css`
      display: flex;
      background: ${headerBg};
      height: ${height}px;
      width: ${width}px;
      align-items: center;
      margin: 0.2em auto;
    `,
    buttonWrapper: css`
      display: flex;
      flex-direction: column;
      margin: 0px 0.5em;
      align-items: center;
    `,
    button: css`
      margin: 4.6px auto;
    `,
    filterItem: css`
      margin: 0px 0.5em;
      flex-grow: 1;
      flex-shrink: 1;
    `,
  };
};
