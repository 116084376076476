import { PanelPlugin } from '@grafana/data';
import { HeaderGroupEditor, FieldsEditor } from './editors';
import { Settings } from 'types';
import { List } from './pages/List';

// ts-prune-ignore-next
export const plugin = new PanelPlugin<Settings>(List).useFieldConfig().setPanelOptions((builder) => {
  return builder
    .addCustomEditor({
      id: 'headerGroups',
      path: 'headerGroups',
      name: 'Header groups',
      editor: HeaderGroupEditor,
    })
    .addCustomEditor({
      id: 'fieldSettings',
      path: 'fieldSettings',
      name: 'Display fields',
      editor: FieldsEditor,
    })
    .addBooleanSwitch({
      path: 'showDownloadButton',
      name: 'Show Download Button',
    })
    .addTextInput({
      path: 'id',
      name: 'Voyage ID',
      defaultValue: 'voyageid',
    })
    .addTextInput({
      path: 'voyage_no',
      name: 'Voyage #',
      defaultValue: 'voyage',
    })
    .addTextInput({
      path: 'vessel_id',
      name: 'Vessel ID',
      defaultValue: 'imo',
    })
    .addTextInput({
      path: 'vessel_name',
      name: 'Vessel name',
      defaultValue: 'vessel',
    })
    .addTextInput({
      path: 'start_time',
      name: 'Start time',
      defaultValue: 'departuretime',
    })
    .addTextInput({
      path: 'end_time',
      name: 'End time',
      defaultValue: 'arrivaltime',
    });
});
