import React, { useCallback } from 'react';
import { StandardEditorProps } from '@grafana/data';
import { Button, IconButton, Input, VerticalGroup } from '@grafana/ui';
import { css } from '@emotion/css';

export const HeaderGroupEditor: React.FC<StandardEditorProps<string[]>> = (props) => {
  const { value, onChange } = props;

  const handleOnAdd = useCallback(() => {
    let newvalue = value || [];
    newvalue.push('');
    onChange(newvalue);
  }, [value, onChange]);

  const handleOnChange = useCallback(
    (val: string, index: number) => {
      let newvalue = (value || []).map((e, idx) => (idx === index ? val : e));
      onChange(newvalue);
    },
    [value, onChange]
  );

  const handleOnRemove = useCallback(
    (idx: number) => () => {
      let newvalue = (value || []).filter((e, i) => idx !== i);
      onChange(newvalue);
    },
    [value, onChange]
  );

  return (
    <VerticalGroup>
      {value?.map((e, idx) => (
        <div
          key={idx + e}
          className={css`
            width: 100%;
          `}
        >
          <Input
            defaultValue={e}
            onBlur={(e) => handleOnChange(e.currentTarget.value, idx)}
            // @ts-ignore
            css={undefined}
            suffix={<IconButton name="times" onClick={handleOnRemove(idx)} />}
          />
        </div>
      ))}
      <Button onClick={handleOnAdd}>Add</Button>
    </VerticalGroup>
  );
};
