import React, { useState } from 'react';
import { Field, Input, Select, Switch, CollapsableSection, TextArea } from '@grafana/ui';
import { css } from '@emotion/css';
import { Fields } from 'types';

interface Props {
  value: Fields;
  onChange?: (newValue: Fields) => void;
  hidden?: boolean;
  groups?: string[];
}

export const EditorForm: React.FC<Props> = ({ value, onChange, hidden, groups }) => {
  const [linkIsOpen, setLinkIsOpen] = useState(false);
  const [filterIsOpen, setFilterIsOpen] = useState(false);

  return (
    <div
      hidden={hidden}
      className={css`
        margin-top: 10px;
      `}
    >
      <Field label="Group">
        <Select
          options={groups?.map((e) => ({ value: e, label: e }))}
          defaultValue={groups?.map((e) => ({ value: e, label: e })).find((e) => e.value === value.headerGroup)}
          onChange={(e) => onChange?.({ ...value, headerGroup: e.value })}
          // @ts-ignore
          css={undefined}
        />
      </Field>
      <Field label="Header">
        <Input
          defaultValue={value?.label}
          onBlur={(e) => onChange?.({ ...value, label: e.target.value })}
          // @ts-ignore
          css={undefined}
        />
      </Field>
      <Field label="Is hidden">
        <Switch
          value={value?.isHidden}
          onChange={(e) => onChange?.({ ...value, isHidden: !!!value.isHidden })}
          // @ts-ignore
          css={undefined}
        />
      </Field>
      <CollapsableSection label={'Link'} isOpen={linkIsOpen} onToggle={() => setLinkIsOpen(!linkIsOpen)}>
        <Field
          label="Link text"
          description="Text to replace the cell value. $__value is replaced by the original value"
        >
          <Input
            defaultValue={value?.linkText}
            onBlur={(e) => onChange?.({ ...value, linkText: e.target.value })}
            // @ts-ignore
            css={undefined}
          />
        </Field>
        <Field label="URL" description="URL of the link. $__value is replaced by the cell value">
          <Input
            defaultValue={value?.link}
            onBlur={(e) => onChange?.({ ...value, link: e.target.value })}
            // @ts-ignore
            css={undefined}
          />
        </Field>
        <Field label="Tooltip" description="Text to display on hover. $__value is replaced by the cell value">
          <Input
            defaultValue={value?.linkTooltip}
            onBlur={(e) => onChange?.({ ...value, linkTooltip: e.target.value })}
            // @ts-ignore
            css={undefined}
          />
        </Field>
      </CollapsableSection>
      <CollapsableSection label={'Filter'} isOpen={filterIsOpen} onToggle={() => setFilterIsOpen(!filterIsOpen)}>
        <Field label="Is filterable">
          <Switch
            value={value?.isFilterable}
            onChange={(e) => onChange?.({ ...value, isFilterable: !!!value.isFilterable })}
            // @ts-ignore
            css={undefined}
          />
        </Field>
        <Field label="Is timerange" hidden={!value?.isFilterable}>
          <Switch
            value={value?.isTimeRange}
            onChange={(e) => onChange?.({ ...value, isTimeRange: !!!value.isTimeRange })}
            // @ts-ignore
            css={undefined}
          />
        </Field>
        <Field label="SQL for filter value" hidden={!value?.isFilterable || value?.isTimeRange}>
          <TextArea
            defaultValue={value?.filterSql}
            onBlur={(e) => onChange?.({ ...value, filterSql: e.target.value })}
            // @ts-ignore
            css={undefined}
          />
        </Field>
        <Field label="SQL WHERE clause" hidden={!value?.isFilterable || value?.isTimeRange}>
          <TextArea
            defaultValue={value?.filterWhere}
            onBlur={(e) => onChange?.({ ...value, filterWhere: e.target.value })}
            // @ts-ignore
            css={undefined}
          />
        </Field>
        <Field label="SQL for selected values" hidden={!value?.isFilterable || value?.isTimeRange}>
          <TextArea
            defaultValue={value?.filterSelected}
            onBlur={(e) => onChange?.({ ...value, filterSelected: e.target.value })}
            // @ts-ignore
            css={undefined}
          />
        </Field>
      </CollapsableSection>
    </div>
  );
};
