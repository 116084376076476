import React, { FC, ReactElement } from 'react';
import { formattedValueToString } from '@grafana/data';

import { TableCellProps } from './types';
import { Icon, getAvailableIcons } from '@grafana/ui';

export const DefaultCell: FC<TableCellProps> = (props) => {
  const { field, cell, tableStyles, cellProps } = props;

  const cellStyle = tableStyles.cellContainer;

  if (cell.value?.title || cell.value?.linkIcon) {
    let value = cell.value as {
      href: string;
      title?: string;
      tooltip?: string;
      linkIcon?: {
        name?: string;
        link?: string;
        tooltip?: string;
      };
    };
    let title = value.title?.split(/[{}]/);

    // @ts-ignore
    let link = title?.map((e, idx) => (getAvailableIcons().includes(e) ? <Icon key={idx} name={e} /> : e));

    return (
      // @ts-ignore
      <div {...cellProps} className={cellStyle}>
        <div className={tableStyles.cellWrapper}>
          {value.href ? (
            <a className={tableStyles.cellText} href={value.href} title={value.tooltip}>
              {link}
            </a>
          ) : (
            <>{link}</>
          )}
          {value.linkIcon?.link && (
            <a className={'cell-icon'} href={value.linkIcon.link} title={value.linkIcon.tooltip}>
              {/* @ts-ignore */}
              <Icon name={value.linkIcon.name} />
            </a>
          )}
        </div>
      </div>
    );
  }

  let displayValue = field?.display!(cell.value);

  let value: string | ReactElement;
  let flag = React.isValidElement(displayValue);
  if (flag) {
    value = cell.value;
  } else {
    value = formattedValueToString(displayValue);
  }

  return (
    // @ts-ignore
    <div {...cellProps} className={cellStyle}>
      <div className={tableStyles.cellText}>{value}</div>
    </div>
  );
};
