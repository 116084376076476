import { DataQuery, DataQueryRequest, DataSourceRef, dateTime } from '@grafana/data';

export const getQuery: (sql: string, ds: DataSourceRef, refId?: string, key?: string) => DataQuery = (
  sql,
  ds,
  refId = 'A',
  key = '0'
) => ({
  datasource: ds,
  format: 'table',
  group: [],
  key,
  metricColumn: 'none',
  rawQuery: true,
  rawSql: sql,
  refId,
  maxDataPoints: 1,
});

const to = dateTime('now-30h');
const _from = dateTime('now');

export const dataQueryRequest: (query: DataQuery) => DataQueryRequest = (query) => ({
  app: 'krohnemarine-list-panel',
  dashboardId: 0,
  interval: '1m',
  intervalMs: 21600000,
  range: {
    from: dateTime(_from),
    to,
    raw: {
      from: _from,
      to,
    },
  },
  requestId: 'list-' + query.refId,
  scopedVars: {},
  startTime: _from.valueOf(),
  targets: [query],
  timezone: 'browser',
});
