import React, { useState, useCallback, useEffect } from 'react';
import { AsyncMultiSelect, Field, LoadOptionsCallback, TimeRangeInput } from '@grafana/ui';
import { Fields } from 'types';
import { css, cx } from '@emotion/css';
import { DataFrame, DataQueryResponse, DataSourceRef, SelectableValue, TimeRange } from '@grafana/data';
import { dataQueryRequest, getQuery } from 'utils/queries';
import { getDataSourceSrv, getTemplateSrv } from '@grafana/runtime';
import { from } from 'rxjs';
import { isString } from 'lodash';

interface Props {
  value: Fields;
  onChange?: (e: Fields) => void;
  datasource?: DataSourceRef;
  className?: string;
}

export const FilterComponent: React.FC<Props> = (props) => {
  const { value, onChange, datasource: datasourceName, className } = props;
  const { label, value: name, isTimeRange, filterSql, filterValue, filterWhere, filterSelected } = value;
  const [items, setItems] = useState<SelectableValue[]>([]);

  const dataFetcher = useCallback(
    (whereFilterCallback?: (value: string) => string, queryid?: string) =>
      async (query: string, cb?: LoadOptionsCallback<string>) => {
        let selectableData: SelectableValue[] = [];
        if (datasourceName && filterSql) {
          let whereFilter = whereFilterCallback?.(query) || '';

          let refId = name;
          if (isString(queryid)) {
            refId = queryid;
          }
          let request = getQuery(
            getTemplateSrv().replace(filterSql.replace('$__where', whereFilter)),
            datasourceName,
            refId,
            refId
          );
          const datasource = await getDataSourceSrv().get(datasourceName);
          await from(datasource.query(dataQueryRequest(request)) as Promise<DataQueryResponse>).forEach((response) => {
            let result = response.data[0] as DataFrame;
            for (let index = 0; index < (result?.length || 0); index++) {
              selectableData.push({
                label: (result.fields.find((e: any) => e.name === '__text') || result.fields[0]).values.get(index),
                value: (result.fields.find((e: any) => e.name === '__value') || result.fields[0]).values.get(index),
              });
            }
          });
        }
        return selectableData;
      },
    [datasourceName, name, filterSql]
  );

  useEffect(() => {
    if (isTimeRange) {
      return;
    } else if (Array.isArray(filterValue) && filterValue.filter((e) => e).length) {
      dataFetcher(
        (value) => filterSelected?.replace('$__value', value) || '',
        '_' + name
      )(filterValue.join(', ')).then(setItems);
    } else {
      setItems([]);
    }
  }, [name, isTimeRange, dataFetcher, filterValue, filterSelected]);

  if (isTimeRange) {
    return (
      <Field
        label={label}
        className={cx(
          className,
          css`
            min-width: 360px;
          `
        )}
      >
        <TimeRangeInput
          clearable={false}
          placeholder="Set a time filter"
          isReversed={false}
          // @ts-ignore
          value={filterValue as TimeRange}
          onChange={(e) => {
            if (e.from.isValid() && e.to.isValid()) {
              onChange?.({ ...value, filterValue: e });
            } else {
              onChange?.({ ...value, filterValue: undefined });
            }
          }}
          // @ts-ignore
          css={undefined}
        />
      </Field>
    );
  } else {
    return (
      <Field label={label} className={className}>
        <AsyncMultiSelect
          value={items}
          loadOptions={dataFetcher((value) => (value && filterWhere?.replace('$__value', value)) || '')}
          defaultOptions
          onChange={(e) => onChange?.({ ...value, filterValue: e.map((e) => e.value || '').filter((e) => e !== '') })}
          // @ts-ignore
          css={undefined}
        />
      </Field>
    );
  }
};
