import React, { FC, useCallback, useMemo, useState, useEffect } from 'react';
import {
  DataSourceRef,
  dateMath,
  dateTime,
  dateTimeParse,
  InterpolateFunction,
  KeyValue,
  LoadingState,
  PanelProps,
  TimeRange,
  UrlQueryMap,
} from '@grafana/data';

import { locationService, getTemplateSrv } from '@grafana/runtime';

import { Fields, Settings } from '../types';
import { Table } from 'components/Table';
import { FilterForm } from 'components/Filter/FilterForm';
import { TableSortByFieldState } from '@grafana/ui';
import { getExcelFile, resolveVoyages } from 'file-formatting';
import { interpolate } from 'components/Table/utils';

const FILTER_PREFIX = 'filter';
const FILTER_VAR_PREFIX = 'var-filter';

const SORT_VAR_PREFIX = 'var-sort';

const getVariable: (name: string, replaceVariables: InterpolateFunction) => string[] = (name, replaceVariables) => {
  let vars = getTemplateSrv()
    .getVariables()
    .find((e) => e.name === name);
  // @ts-ignore
  if (vars?.current.text.includes('All')) {
    return [];
  } else {
    return replaceVariables('$' + name, undefined, 'csv')
      .split(',')
      .filter((e) => !e.startsWith(FILTER_PREFIX));
  }
};

export const List: FC<PanelProps<Settings>> = (props) => {
  const { options, data, replaceVariables, height, width, onOptionsChange } = props;
  const { fieldSettings } = options;
  const [downloadStatus, setDownloadStatus] = useState<'ready' | 'loading'>('ready');
  const [datasource, setDatasource] = useState<DataSourceRef>();

  const [filters, setFilters] = useState<Fields[]>([]);
  const [timeFilters, setTimeFilters] = useState<KeyValue<TimeRange | undefined>>({});

  const variables = getTemplateSrv().getVariables();

  const handleOnColumnResize = useCallback(
    (fieldDisplayName: string, width: number) => {
      let _ = options.fieldSettings?.map((e) => (e.value === fieldDisplayName ? { ...e, width } : e));
      onOptionsChange({ ...options, fieldSettings: _ });
    },
    [options, onOptionsChange]
  );

  const handleDownloadClick = useCallback(async () => {
    setDownloadStatus('loading');
    let voyages = resolveVoyages(data);
    if (voyages?.length) {
      await getExcelFile(voyages);
    }
    setDownloadStatus('ready');
  }, [data]);

  const handleOnFiltersChange = useCallback((e: Fields) => {
    let queryMap: UrlQueryMap = {};
    const name = e.value;
    const value = e.filterValue;
    if (Array.isArray(value)) {
      let values: string[] | null = value;
      if (values.length === 0) {
        values = [''];
      }
      queryMap[FILTER_VAR_PREFIX + name] = values;
    } else {
      if (value) {
        queryMap[FILTER_VAR_PREFIX + name + '_from'] = value?.from.valueOf() || 0;
        queryMap[FILTER_VAR_PREFIX + name + '_to'] = value?.to.valueOf() || Infinity;
      } else {
        queryMap[FILTER_VAR_PREFIX + name + '_from'] = undefined;
        queryMap[FILTER_VAR_PREFIX + name + '_to'] = undefined;
      }
      setTimeFilters((f) => ({ ...f, [name]: value }));
    }
    locationService.partial(queryMap);
  }, []);

  useEffect(() => {
    let filters = fieldSettings?.map((fieldSetting) => {
      if (fieldSetting.isTimeRange) {
        if (timeFilters[fieldSetting.value]?.from) {
          return {
            ...fieldSetting,
            filterValue: timeFilters[fieldSetting.value] as TimeRange,
          };
        }
        let from = dateTimeParse(+getVariable(FILTER_PREFIX + fieldSetting.value + '_from', replaceVariables)[0]);
        let to = dateTimeParse(+getVariable(FILTER_PREFIX + fieldSetting.value + '_to', replaceVariables)[0]);
        if (!dateMath.isValid(from) || !dateMath.isValid(to)) {
          from = dateTime(null);
          to = dateTime(null);
        }
        return {
          ...fieldSetting,
          filterValue: {
            from,
            to,
            raw: {
              from,
              to,
            },
          },
        };
      } else {
        let filterValue = getVariable(FILTER_PREFIX + fieldSetting.value, replaceVariables);
        return {
          ...fieldSetting,
          filterValue,
        };
      }
    });
    if (filters) {
      setFilters(filters);
    }
    if (data.request?.targets[0]?.datasource) {
      setDatasource(data.request.targets[0].datasource);
    }
  }, [fieldSettings, replaceVariables, data, timeFilters]);

  const handleOnSortChange = useCallback(
    (states: TableSortByFieldState[]) => {
      let queryMap: UrlQueryMap = {};
      queryMap[SORT_VAR_PREFIX] = states
        .map((state) => ({ ...state, field: fieldSettings?.find((field) => state.displayName === field.value) }))
        .filter((state) => state.field)
        .map((state) => `${state.field?.value} ${state.desc ? 'desc' : 'asc'}`);
      locationService.partial(queryMap);
    },
    [fieldSettings]
  );

  const sorts = useMemo<TableSortByFieldState[]>(() => {
    return (
      fieldSettings
        ?.filter((fieldSetting) => fieldSetting.initialSort === 'asc' || fieldSetting.initialSort === 'desc')
        .map((e) => ({ displayName: e.value, desc: e.initialSort === 'desc' })) || []
    );
  }, [fieldSettings]);

  const units = useMemo<KeyValue>(() => {
    const unitSerie = data?.series.find(serie => serie.refId === 'Units');
    return unitSerie?.fields.reduce((val: any, field: any) => {
      val[field.name] = field.values.toArray()[0];
      return val;
    }, {}) ?? {};
  }, [data?.series])

  const dataSeries = data?.series?.filter(serie => serie.refId !== 'Units');

  const setting = useMemo<Settings>(() => {
    return { 
      ...options, 
      fieldSettings: fieldSettings?.map((row) => ({ 
        ...row, 
        label: interpolate(replaceVariables(row.label), units)
      })) 
    };
  }, [options, fieldSettings, replaceVariables, units]);
  const hasFilter = filters.filter((e) => e.isFilterable).length || options.showDownloadButton;
  const adjust = hasFilter ? 80 : 0;

  return (
    <>
      {hasFilter ? (
        <FilterForm
          width={width}
          height={adjust}
          onFiltersChange={handleOnFiltersChange}
          filters={filters || []}
          datasource={datasource}
          showDownloadButton={options.showDownloadButton}
          onDownloadClick={() => {
            if (downloadStatus === 'loading') {
              return;
            }
            handleDownloadClick();
          }}
          status={downloadStatus}
        />
      ) : null}
      {dataSeries.map((e, idx) => {
        if (e) {
          return (
            <Table
              key={idx} // Add a unique key prop
              loading={data.state === LoadingState.Loading}
              onSortByChange={handleOnSortChange}
              initialSortBy={sorts}
              data={e}
              width={width}
              height={height - adjust}
              settings={setting}
              onColumnResize={handleOnColumnResize}
              variables={variables}
            />
          );
        } else {
          return null;
        }
      })}
    </>
  );
};
